import React from "react"
import { graphql } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"

/* Import Global Components */
import Page from "~components/page"
import Post from "~components/post"
/* Import Local Styles */
import "./post.scss"

// fixed(width: 1200, toFormat: PNG, height: 630) {
//   src
// }

const PostTemplate = ({ data: { sanityPost: post } }) => {
  const imageData = post.heroImage
    ? getGatsbyImageData(
        post.heroImage.asset.id,
        {
          maxWidth: 1200,
          maxHeight: 630,
          toFormat: "png",
        },
        { projectId: "2pq7mrv0", dataset: "production" }
      )
    : null
  return (
    <Page
      title={post.postTitle}
      description={post.postExcerpt}
      article={true}
      authors={post.postAuthor}
      datePublished={post.publicationDate}
      image={imageData ? imageData.images.fallback.src : null}
    >
      <Post post={post} />
    </Page>
  )
}

export default PostTemplate

export const query = graphql`
  query($id: String!) {
    sanityPost(id: { eq: $id }) {
      id
      _id
      slug
      postTitle
      postAuthor {
        _id
        name
        slug
        publishOnWebsite
      }
      publicationDate
      postSeries {
        _id
        title
        slug
      }
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      _rawPostSummary
      postExcerpt
      reports {
        _id
        slug
        publishOnWebsite
        title
      }
      heroImage {
        asset {
          id
        }
      }
    }
  }
`
