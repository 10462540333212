import React, { useState, useEffect, useContext } from "react"
import { format } from "date-fns"
import find from "lodash.find"

// Import Global Context(s)
import UserContext from "~context/user"

/* Import Global Components */
import Link from "~components/link"
import Authors from "~components/authors"
import BlockContent from "~components/blockContent"

/* Import Local Styles */
import "./post.scss"

const Post = ({ post }) => {
  const [isPostSaved, setIsPostSaved] = useState(false)
  const [user, { savePost, removePost }] = useContext(UserContext)

  useEffect(() => {
    if (user && user.research) {
      const isPostSaved =
        find(user.research.posts, savedPost => {
          return savedPost.id === post.id
        }) !== undefined
      setIsPostSaved(isPostSaved)
    }
  }, [post, user])

  const handleSavePostClick = () => {
    if (user) {
      savePost(post)
    }
  }

  const handleRemovePostClick = () => {
    if (user) {
      removePost(post)
    }
  }

  return (
    <div className="post">
      <div>
        <p className="post-title">{post.postTitle}</p>
        <p>
          Posted by <Authors authors={post.postAuthor} /> on{" "}
          {format(new Date(post.publicationDate), "yyyy-MM-dd")}
        </p>
      </div>
      {post.postSeries && (
        <div>
          <p>
            Part of a series on{" "}
            <Link to={post.postSeries.slug}>{post.postSeries.title}</Link>
          </p>
        </div>
      )}
      <div>
        {/* if user has saved this post, show remove option */}
        {isPostSaved ? (
          <button onClick={handleRemovePostClick}>Remove Post</button>
        ) : (
          <button onClick={handleSavePostClick}>Save Post</button>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
      <BlockContent blocks={post._rawPostContent} className="post-content" />
      {/* related posts */}
      {post.reports && post.reports.length > 0 ? (
        <div>
          <p>Associated with Reports</p>
          {post.reports.map(report => {
            return report.publishOnWebsite ? (
              <Link key={report._id} to={report.slug}>
                {report.title}
              </Link>
            ) : (
              <span key={report._id}>{report.title}</span>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
export default Post
